<button mat-icon-button (click)="changeMonth(-1)" [matTooltip]="'Předchozí měsíc' | translate" >
  <mat-icon>{{ config.icons.arrow_left }}</mat-icon>
</button>
<button mat-icon-button (click)="changeMonth(1)" [matTooltip]="'Další měsíc' | translate">
  <mat-icon>{{ config.icons.arrow_right }}</mat-icon>
</button>
<span class="app-font-bold">{{ month }}</span>
<mat-form-field>
  <input matInput [(ngModel)]="year" pattern="[0-9]+" type="text" (keyup.enter)="changeYear()">
</mat-form-field>
