<mat-card (click)="setTitul()">
  <mat-card-header>
    <mat-card-title>{{ item.meta_nazev }}{{ item.nazev }}</mat-card-title>
    <mat-card-subtitle>{{ item['date'] }}</mat-card-subtitle>
  </mat-card-header>
  <mat-card-content>
    <p>
      <button mat-flat-button color="accent">
        <span class="app-fl-low">{{ 'desc.records' | translate }}</span> {{ item['total'] }}
      </button>
    </p>
  </mat-card-content>
  <mat-card-actions>
    <button mat-button *ngIf="item['num_exemplare'] > 0"><span class="app-fl-low">{{ 'desc.specimens' | translate }}</span> {{ item['num_exemplare'] }}</button>
    <button mat-button *ngIf="item['num_exemplare'] === 0"><span class="app-fl-low">{{ 'desc.specimens' | translate }}</span> {{ item['num_exemplare'] }}</button>
    <button mat-button *ngIf="item['num_mutace'] > 0"><span class="app-fl-low">{{ 'desc.mutations' | translate }}</span> {{ item['num_mutace'] }}</button>
    <button mat-button *ngIf="item['num_mutace'] === 0"><span class="app-fl-low">{{ 'desc.mutations' | translate }}</span> {{ item['num_mutace'] }}</button>
    <button mat-button *ngIf="item['num_vlastniku'] > 0"><span class="app-fl-low">{{ 'desc.owners' | translate }}</span> {{ item['num_vlastniku'] }}</button>
    <button mat-button *ngIf="item['num_vlastniku'] === 0"><span class="app-fl-low">{{ 'desc.owners' | translate }}</span> {{ item['num_vlastniku'] }}</button>
    <button mat-button *ngIf="item['digitalizovano'] > 0"><span class="app-fl-low">{{ 'desc.digitized' | translate }}</span> {{ item['digitalizovano'] }}</button>
    <button mat-button *ngIf="item['digitalizovano'] === 0"><span class="app-fl-low">{{ 'desc.digitized' | translate }}</span> {{ item['digitalizovano'] }}</button>
  </mat-card-actions>
</mat-card>
