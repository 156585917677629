<div class="app-calendar-day-content" [class.app-disabled]="isOtherMonth()"
  [class.app-calendar-day-special-holiday]="special.type === 'svatek'"
  [class.app-calendar-day-special-event]="special.type === 'udalost'">
  <div fxLayout="row">
    <div fxFlex>
      <div *ngIf="special.type" class="app-calendar-day-content-title">
        <span [matTooltip]="special.title" matTooltipPosition="above">{{day | date: 'dd'}}</span>
      </div>
      <div *ngIf="!special.type" class="app-calendar-day-content-title">
        {{day | date: 'dd'}}
      </div>
    </div>
    <!-- <div fxFlexAlign="right" class="app-calendar-day-content-menu">
      <a [matMenuTriggerFor]="menu">
        <mat-icon>{{ config.icons.more_vert }}</mat-icon>
      </a>
      <mat-menu #menu="matMenu" [id]="'calendar-menu'">
        <button mat-menu-item>{{ 'button.add_specimen' | translate }}</button>
        <button mat-menu-item>{{ 'button.add_background' | translate }}</button>
        <button mat-menu-item>{{ 'button.delete_all_mutations' | translate }}</button>
      </mat-menu>
    </div> -->
  </div>
  <div *ngFor="let e of issues; let idx = index" class="app-calendar-day-content-item">
<!--    <div fxLayout="row" *ngIf="idx < 3" [class]="'app-state-'+e.state" [routerLink]="['/issue', e.id]">-->
    <div fxLayout="row" [class]="'app-state-'+e.state" (click)="showBarCodes(e)">
      <div fxFlex class="app-fx-left">
        {{ e.cislo }} {{ e.mutace }} {{e.znak_oznaceni_vydani}} {{ 'record.publication.' + e.vydani | translate }}
      </div>
      <div fxFlexAlign="right">
        <span *ngIf="e.exemplare" class="app-count-ex right app-cursor-pointer" [matTooltip]="'calendar.tooltip.specimen_count' | translate" matTooltipPosition="above">{{ e.exemplare.length }}</span>
        <span *ngIf="e.digitalizovano" class="right" [matTooltip]="'calendar.tooltip.digitized' | translate" matTooltipPosition="above">
          <mat-icon>{{ config.icons.digitized }}</mat-icon>
        </span>
      </div>
    </div>
<!--    <div *ngIf="idx === 3" class="app-btn-show-more">-->
<!--      <a [routerLink]="['/calendar', id, 'list', e.datum_vydani_den]" [matTooltip]="'calendar.tooltip.show_all_issues' | translate">-->
<!--        <mat-icon>{{ config.icons.link }}</mat-icon> <span>{{ issues.length - 3 }}</span>-->
<!--        {{ 'calendar.next_issues' | translate }}-->
<!--      </a>-->
<!--    </div>-->
  </div>
</div>
