<h1 mat-dialog-title>
  <ng-container [ngSwitch]="editType">
    <span *ngSwitchCase="'new'">{{ 'desc.add_specimen' | translate }}</span>
    <span *ngSwitchCase="'duplicate'">{{ 'desc.duplicate_specimen' | translate }}</span>
    <span *ngSwitchDefault>{{ 'desc.edit_specimen' | translate }}</span>
  </ng-container>
</h1>
<div mat-dialog-content>
  <div class="app-record-name">
    {{ 'desc.record' | translate }}: <span class="app-font-bold">{{ issue.nazev }}, {{ issue.datum_vydani | date: 'dd.MM.yyyy' }}</span>
  </div>
  <ng-container [ngSwitch]="editType">
    <div *ngSwitchCase="'new'" fxLayout="row" fxLayoutGap="16px" class="app-gap-top">
      <mat-form-field fxFlex>
        <mat-label>{{ 'record.ownership' | translate }}</mat-label>
        <mat-select [id]="'owner'" [name]="'owner'" [(ngModel)]="exemplar.vlastnik">
          <mat-option *ngFor="let owner of state.owners" [value]="owner.id">{{owner.name}}</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field fxFlex>
        <input matInput [placeholder]="'record.barcode' | translate" [id]="'carovy_kod'" [name]="'carovy_kod'" [(ngModel)]="exemplar.carovy_kod" type="text">
      </mat-form-field>
    </div>
    <div *ngSwitchCase="'duplicate'">
      <div>
        {{ 'desc.specimen' | translate }}: <span class="app-font-bold">{{ exemplar.vlastnik }} {{ exemplar.carovy_kod }}</span>
      </div>
      <div fxLayout="row" fxLayoutGap="16px" class="app-gap-top">
        <mat-form-field fxFlex="33.3333%">
          <input matInput [matDatepicker]="pickerOd" placeholder="Choose a date" [(ngModel)]="startDate">
          <mat-datepicker-toggle matSuffix [for]="pickerOd"></mat-datepicker-toggle>
          <mat-datepicker #pickerOd></mat-datepicker>
        </mat-form-field>
        <mat-form-field fxFlex="33.3333%">
          <input matInput [matDatepicker]="pickerTo" placeholder="Choose a date" [(ngModel)]="endDate">
          <mat-datepicker-toggle matSuffix [for]="pickerTo"></mat-datepicker-toggle>
          <mat-datepicker #pickerTo></mat-datepicker>
        </mat-form-field>
        <div fxFlex="33.3333%" fxLayout="row">
          <mat-checkbox fxFlexAlign="center" [(ngModel)]="onspecial" id="onSpecialDays">{{ 'modal.on_special_days' | translate }}</mat-checkbox>
        </div>
      </div>
    </div>
    <div *ngSwitchDefault>
      {{ 'desc.specimen' | translate }}: {{ exemplar.vlastnik }} <span class="app-font-bold">{{ exemplar.carovy_kod }}</span>
      <span class="app-pipe"></span>
      <a (click)="editSvazek(exemplar.carovy_kod)" [matTooltip]="'desc.edit_volume' | translate" [matTooltipPosition]="'above'">
        <mat-icon>{{ config.icons.edit_volume }}</mat-icon>
      </a>
    </div>
  </ng-container>

  <div fxLayout="row" fxLayoutGap="16px">
    <mat-form-field fxFlex="33.3333%">
      <mat-label>{{ 'desc.select_state' | translate }}</mat-label>
      <mat-select [id]="'stav'" [name]="'stav'" [(ngModel)]="exemplar.stav" multiple [disabled]="!state.logged || !isOwner">
        <mat-option *ngFor="let stav of state.stavy" [value]="stav">
          {{ 'record.StavIssue.' + stav | translate }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field  fxFlex="33.3333%">
      <input matInput [placeholder]="'record.state_com' | translate" [id]="'stav_popis'" [name]="'stav_popis'" [(ngModel)]="exemplar.stav_popis" type="text" [disabled]="!state.logged || !isOwner">
    </mat-form-field>
    <mat-form-field  fxFlex="33.3333%">
      <input matInput [placeholder]="'record.signatura' | translate" id="signatura" name="signatura" [(ngModel)]="exemplar.signatura" type="text" autocomplete="on" [disabled]="!state.logged || !isOwner">
    </mat-form-field>
  </div>

  <div fxLayout="row" fxLayoutGap="16px">
    <mat-form-field fxFlex="50%">
      <input matInput [placeholder]="'record.oznaceni' | translate" [id]="'oznaceni'" [name]="'oznaceni'" [(ngModel)]="exemplar.oznaceni" type="text" #box (keyup)="filterOznaceni(box.value)" [disabled]="!state.logged || !isOwner">
    </mat-form-field>
    <mat-form-field fxFlex="50%">
      <input matInput [placeholder]="'record.popis_oznaceni_vydani' | translate" [id]="'popis_oznaceni_vydani'" [name]="'popis_oznaceni_vydani'" [(ngModel)]="exemplar.popis_oznaceni_vydani" type="text" [disabled]="!state.logged || !isOwner">
    </mat-form-field>
  </div>

  <!-- <div *ngIf="showPages">
    <span class="app-font-bold">{{ 'record.lost_pages' | translate }}</span>
    <div class="app-grid-wrapper">
      <div *ngFor="let page of pagesRange">
        <mat-checkbox [id]="'pg'+page.label" [(ngModel)]="page.sel">{{ page.label }}</mat-checkbox>
      </div>
    </div>
  </div>  -->

  <div *ngIf="showPages">
    <span class="app-font-bold">{{ 'record.pages_missing' | translate }}</span>
    <div class="app-grid-wrapper">
      <div *ngFor="let page of exemplar.pagesRange.missing">
        <mat-checkbox [id]="'pgm'+page.label" [(ngModel)]="page.sel">{{ page.label }}</mat-checkbox>
      </div>
    </div>

    <span class="app-font-bold">{{ 'record.pages_damaged' | translate }}</span>
    <div class="app-grid-wrapper">
      <div *ngFor="let page of exemplar.pagesRange.damaged">
        <mat-checkbox [id]="'pgd'+page.label" [(ngModel)]="page.sel">{{ page.label }}</mat-checkbox>
      </div>
    </div>
  </div>


</div>
<div mat-dialog-actions>
  <button mat-button (click)="ok()" [disabled]="!state.logged || !isOwner">OK</button>
  <button mat-button (click)="cancel()">{{ 'button.cancel' | translate }}</button>
</div>
