<button mat-icon-button  (click)="changeView('month')" [class.active]="state.calendarView === 'month'" [matTooltip]="'Zobrazit měsíc' | translate">
  <mat-icon>{{ config.icons.view_month }}</mat-icon>
</button>

<button mat-icon-button  (click)="changeView('list')" [class.active]="state.calendarView === 'list'" [matTooltip]="'Zobrazit seznam' | translate">
  <mat-icon>{{ config.icons.view_list }}</mat-icon>
</button>

<button mat-icon-button [routerLink]="'/result/'+ state.currentTitul.id" [matTooltip]="'Zobrazit tabulku' | translate">
  <mat-icon>{{ config.icons.view_table }}</mat-icon>
</button>
