<h1 mat-dialog-title>{{ 'Add titul' | translate }}</h1>
<div mat-dialog-content>
  <mat-form-field>
    <input matInput [placeholder]="'record.meta_title' | translate" id="v" name="meta_nazev" type="text" [(ngModel)]="titul.meta_nazev">
  </mat-form-field>
  
  <!-- <mat-form-field>
    <input matInput [placeholder]="'record.uuid_title' | translate" id="v" name="uuid" type="text" [(ngModel)]="titul.uuid">
  </mat-form-field> -->
  
  <!-- <mat-form-field>
    <input matInput [placeholder]="'record.pages_count' | translate" id="pocet_stran" name="pocet_stran" [(ngModel)]="titul.pocet_stran" type="text">
  </mat-form-field> -->
  
  <mat-form-field>
    <mat-label>{{ 'Periodicita' | translate }}</mat-label>
    <mat-select [id]="'periodicita'" [name]="'periodicita'" [(ngModel)]="titul.periodicita">
      <mat-option *ngFor="let p of state.periods" [value]="p">{{'record.periodicita.' + p | translate}}</mat-option>
    </mat-select>
  </mat-form-field>
</div>
<div mat-dialog-actions>
  <button mat-button (click)="ok()">OK</button>
  <button mat-button (click)="cancel()">{{'button.cancel' | translate}}</button>
</div>


<!-- <mz-modal [fixedFooter]="true">
  <mz-modal-header>
    <span>{{'Add titul' | translate}}</span>
  </mz-modal-header>

  <mz-modal-content>
    <mz-input-container>
      <input mz-input [label]="'record.meta_title' | translate" id="v" name="meta_nazev" type="text" [(ngModel)]="titul.meta_nazev">
    </mz-input-container>
    
    <mz-input-container>
      <input mz-input [label]="'record.uuid_title' | translate" id="v" name="uuid" type="text" [(ngModel)]="titul.uuid">
    </mz-input-container>
    
    <mz-input-container>
      <input mz-input [label]="'record.pages_count' | translate" id="pocet_stran" name="pocet_stran" [(ngModel)]="titul.pocet_stran" type="text">
    </mz-input-container>
    
    <mz-select-container>
      <select mz-select [label]="'Periodicita'" [id]="'periodicita'" [name]="'periodicita'" [(ngModel)]="titul.periodicita">
        <option *ngFor="let p of state.periods" [value]="p">{{'record.periodicita.' + p | translate}}</option>
      </select>
    </mz-select-container>   
  </mz-modal-content>

  <mz-modal-footer>
    <button mz-button [flat]="true" (click)="ok()">OK</button>
    <button mz-button [flat]="true" mz-modal-close (click)="cancel()">{{'button.cancel' | translate}}</button>
  </mz-modal-footer>
</mz-modal> -->