<mat-progress-bar *ngIf="loading" mode="indeterminate"></mat-progress-bar>

<div class="app-metatitul-wrapper" fxLayout="row" *ngIf="state.isAdmin">
  <div fxFlex="320px" class="app-fx-left">
    <div *ngIf="state.isAdmin" style="margin: 16px 0 0 16px;">
      <button mat-flat-button color="primary" (click)="newTitul()" type="button">{{ 'desc.add_new_title' | translate }}</button>
    </div>
    <mat-list role="list">
      <mat-list-item role="listitem" *ngFor="let t of state.tituly; let idx=index" [class.app-active]="titul && t.id === titul.id">
        <ng-container [ngSwitch]="(titul && t.id === titul.id) || (!titul && idx===0)">
          <mat-icon *ngSwitchCase="true">{{ config.icons.radio_button_checked }}</mat-icon>
          <mat-icon *ngSwitchDefault>{{ config.icons.radio_button_unchecked }}</mat-icon>
        </ng-container>
        <a (click)="loadTitul(t)">{{ t.meta_nazev }}</a>
      </mat-list-item>
    </mat-list>
  </div>
  <div fxFlex class="app-fx-right" *ngIf="titul">
    <form #userForm="ngForm">
      <div fxLayout="row" fxLayoutGap="16px">
        <mat-form-field fxFlex>
          <input matInput [(ngModel)]="titul.meta_nazev" name="meta_nazev" id="meta_nazev" [placeholder]="'record.meta_title' | translate" required cdkFocusInitial />
        </mat-form-field>

        <!--
         Odstraneno podle #138
          <mat-form-field fxFlex>
          <input matInput [(ngModel)]="titul.uuid" name="uuid" id="uuid" [placeholder]="'record.uuid_title' | translate" />
        </mat-form-field>
          <mat-form-field fxFlex>
          <input matInput [(ngModel)]="titul.pocet_stran" name="pocet_stran" id="pocet_stran" [placeholder]="'record.pages_count' | translate" type="number" min="1" />
        </mat-form-field> -->

        <mat-form-field fxFlex>
          <mat-label>{{ 'record.periodicita.placeholder' | translate }}</mat-label>
          <mat-select [(ngModel)]="titul.periodicita" id="periodicita" name="periodicita">
            <mat-option *ngFor="let p of state.periods" [value]="p">{{ 'record.periodicita.' + p | translate }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div fxLayout="row" fxLayoutGap="16px">
        <mat-form-field fxFlex>
          <mat-label>{{ 'record.poznamka' | translate }}</mat-label>
          <textarea matInput [(ngModel)]="titul.poznamka" name="poznamka" id="poznamka" >{{titul.poznamka}}</textarea>
        </mat-form-field>
      </div>

      <div fxLayout="row" fxLayoutGap="16px" class="custom-checkbox">
        <mat-checkbox (change)="allowNotLoggedUsers($event.checked)" [checked]="titul.show_to_not_logged_users" color="primary"></mat-checkbox>
        <mat-label>{{ 'record.allowNotLoggedUsers' | translate }}</mat-label>
      </div>
      <button mat-flat-button color="primary" (click)="save()" type="button" *ngIf="state.isAdmin" [disabled]="!userForm.form.valid || !state.isAdmin">{{ 'desc.save' | translate }}</button>
      <button mat-flat-button color="primary" (click)="remove()" *ngIf="state.isAdmin" type="button">{{ 'button.delete' | translate }}</button>
      <button mat-flat-button color="accent" [routerLink]="this.routerLink" *ngIf="state.isAdmin" type="button">{{ 'button.specimens' | translate }}</button>
    </form>
  </div>
</div>
