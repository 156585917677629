<mat-progress-bar *ngIf="loading" mode="indeterminate"></mat-progress-bar>

<div class="app-profile-wrapper" fxLayout="row">
  <div fxFlex class="app-fx">
    <h2>{{user.nazev}}</h2>
    <form (ngSubmit)="save()" #userForm="ngForm">
      <div fxLayout="row" fxLayoutGap="16px">
        <mat-form-field fxFlex>
          <input matInput [(ngModel)]="user.nazev" name="nazev" id="nazev" [placeholder]="'desc.username' | translate" required />
        </mat-form-field>
        
        <mat-form-field fxFlex>
          <input matInput [(ngModel)]="user.email" name="email" id="email" [placeholder]="'desc.email' | translate"  />
        </mat-form-field>
        
        <mat-form-field fxFlex>
          <input matInput [(ngModel)]="user.poznamka" name="poznamka" id="poznamka" [placeholder]="'desc.note' | translate"  />
        </mat-form-field>
        
      </div>
      <button mat-flat-button color="primary" (click)="resetHeslo()" type="button" >{{ 'button.password_reset' | translate }}</button>
      <button mat-flat-button color="primary" type="submit" [disabled]="!userForm.form.valid">{{ 'button.save' | translate }}</button>
    </form>
  </div>
</div>