import { Component, OnInit } from '@angular/core';



@Component({
  selector: 'app-admin-owners',
  templateUrl: './admin-owners.component.html',
  styleUrls: ['./admin-owners.component.scss']
})
export class AdminOwnersComponent implements OnInit {



  ngOnInit() {

  }

}
