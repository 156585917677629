<div class="app-table-wrapper">
  <table mat-table [dataSource]="dataSource" [class]="state.loadingData ? 'loadingData' : ''">
    <ng-container matColumnDef="meta_nazev">
      <th mat-header-cell *matHeaderCellDef>{{ 'record.meta_title' | translate }}</th>
      <td mat-cell *matCellDef="let row; let idx = index" style="white-space: nowrap;"  [routerLink]="['/issue', row.id]">
        <span *ngIf="idx === 0 || data[idx-1]['nazev'] !== data[idx]['nazev']">{{ row.nazev }}</span>
        <span *ngIf="idx !== 0 && data[idx-1]['nazev'] === data[idx]['nazev']"> -- || -- </span>
      </td>
    </ng-container>

    <ng-container matColumnDef="mutace">
      <th mat-header-cell *matHeaderCellDef>{{ 'record.mutation' | translate }}</th>
      <td mat-cell *matCellDef="let row; let idx = index">
        <span *ngIf="idx === 0 || data[idx-1]['mutace'] !== data[idx]['mutace']">{{'record.mutations.' + row.mutace | translate}}</span>
        <span *ngIf="idx !== 0 && data[idx-1]['mutace'] === data[idx]['mutace']" class="app-no-content"> --- || ---</span>
      </td>
    </ng-container>

    <ng-container matColumnDef="datum_vydani">
      <th mat-header-cell *matHeaderCellDef>
        {{ 'record.date_publication' | translate }}
      </th>
      <td mat-cell *matCellDef="let row">
        <a (click)="onCalendarClick(row)">{{ row.datum_vydani | date: 'dd.MM.yyyy' }}</a>
      </td>
    </ng-container>

    <ng-container matColumnDef="nazev">
      <th mat-header-cell *matHeaderCellDef>{{ 'desc.name' | translate }}</th>
      <td mat-cell *matCellDef="let row; let idx = index">
        <span *ngIf="idx === 0 || data[idx-1]['nazev'] !== data[idx]['nazev']">{{row.nazev}}</span>
        <span *ngIf="idx !== 0 && data[idx-1]['nazev'] === data[idx]['nazev']" class="app-no-content">--- || ---</span>
      </td>
    </ng-container>

    <ng-container matColumnDef="vydani">
      <th mat-header-cell *matHeaderCellDef>
        {{ 'record.publication.placeholder' | translate }}
      </th>
      <td mat-cell *matCellDef="let row; let idx = index">
        <span *ngIf="row.vydani">{{ 'record.publication.' + row.vydani | translate }}</span>
        <span *ngIf="!row.vydani">{{ 'record.publication.empty' | translate }}</span>
      </td>
    </ng-container>

    <ng-container matColumnDef="cislo">
      <th mat-header-cell *matHeaderCellDef class="app-cell-rotate">
        <div>{{ 'record.number' | translate }}</div>
      </th>
      <td mat-cell *matCellDef="let row">{{ row.cislo }}</td>
    </ng-container>

    <ng-container matColumnDef="pocet_stran">
      <th mat-header-cell *matHeaderCellDef class="app-cell-rotate">
        <div>{{ 'record.pages_count' | translate }}</div>
      </th>
      <td mat-cell *matCellDef="let row">{{ row.pocet_stran }}</td>
    </ng-container>

    <ng-container matColumnDef="add">
      <th mat-header-cell *matHeaderCellDef>
        {{ 'record.total' | translate }}
      </th>
      <td mat-cell *matCellDef="let row">
        {{ row.exemplare ? row.exemplare.length : 0 }}
        <!-- <a (click)="addClick(row)" [matTooltip]="'desc.add_specimen' | translate" matTooltipPosition="above">
          <mat-icon>{{ config.icons.add_circle }}</mat-icon>
        </a> -->
<!--        <a [matTooltip]="'desc.edit_record' | translate" matTooltipPosition="above" (click)="viewIssue(row)">-->
<!--          <mat-icon>{{ config.icons.edit_issue }}</mat-icon>-->
<!--        </a>-->
      </td>
    </ng-container>

    <ng-container *ngFor="let vl of vlastnici" [matColumnDef]="vl.name">
      <th mat-header-cell *matHeaderCellDef [ngClass]="'app-header-cell-' + vl.name.toLowerCase()">
        <div fxLayout="row">
          <div fxFlex>{{ vl.name }}</div>
          <div fxFlexAlign="right">
            <a (click)="toggleCollapsed(vl)" [matTooltip]="vl.collapsed ? ('desc.expand_this_column' | translate) : ('desc.collapse_this_column' | translate)" matTooltipPosition="above">
              <mat-icon>{{vl.collapsed ? config.icons.expand_this_column : config.icons.collapse_this_column }}</mat-icon>
            </a>
          </div>
        </div>
      </th>
      <td mat-cell *matCellDef="let row; let idx = index;" [class.app-col-collapsed]="vl.collapsed">
        <span *ngFor="let ex of row.exemplare; let idxex = index;">
          <ng-container *ngIf="ex.vlastnik === vl.id.toString()">[
            <a (click)="showSvazekOverview(ex.carovy_kod, ex.id_titul)" style="display: inline-block;">{{ ex['carovy_kod'] }}</a>
            <a [matTooltip]="'desc.edit_volume' | translate" matTooltipPosition="above" (click)="viewSvazek(ex['carovy_kod'])">
              <mat-icon>{{ config.icons.edit_volume }}</mat-icon>
            </a>
<!--            <a [matTooltip]="'desc.duplicate_specimen' | translate" matTooltipPosition="above" (click)="duplicate(row, ex)">-->
<!--              <mat-icon>{{ config.icons.duplicate }}</mat-icon>-->
<!--            </a>-->
<!--            <a [matTooltip]="'desc.delete_specimen' | translate" matTooltipPosition="above" (click)="deleteEx(row, ex, idxex)">-->
<!--              <mat-icon>{{ config.icons.delete_specimen }}</mat-icon>-->
<!--            </a>-->
            <a [matTooltip]="formatStav(ex)" matTooltipPosition="above" (click)="viewClick(row, ex)">
<!--              <mat-icon [ngClass]="classByStav(ex)">{{ iconByStav(ex) }}</mat-icon>-->
              <span [ngClass]="classByStav(ex)"></span>
            </a>
          ]</ng-container>
        </span>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr mat-row [ngClass]="{'app-even': idx%2 === 1}" *matRowDef="let row; let idx = index; columns: displayedColumns;"></tr>
  </table>
  <mat-paginator
    [length]="state.numFound"
    [pageSizeOptions]="[25, 50, 500, 1000]"
    [pageSize]="state.rows"
    [pageIndex]="state.currentPage"
    (page)="pageChanged($event)"
    showFirstLastButtons>
  </mat-paginator>
</div>

