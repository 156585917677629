<mat-progress-bar *ngIf="loading" mode="indeterminate"></mat-progress-bar>

<div class="app-admin-users-wrapper" fxLayout="row">
  <div fxFlex="320px" class="app-fx-left">
    <mat-list role="list">
      <mat-list-item role="listitem" *ngFor="let u of users" [ngClass]="{'app-active' : u.id === user.id}">
        <ng-container [ngSwitch]="u.id">
          <mat-icon *ngSwitchCase="user.id">{{ config.icons.radio_button_checked }}</mat-icon>
          <mat-icon *ngSwitchDefault>{{ config.icons.radio_button_unchecked }}</mat-icon>
        </ng-container>
        <a (click)="loadUser(u)">{{ u.nazev }}</a>
      </mat-list-item>
    </mat-list>
  </div>
  <div fxFlex class="app-fx-right" *ngIf="user">
    <form (ngSubmit)="save()" #userForm="ngForm">
      <div fxLayout="row" fxLayoutGap="16px">
        <mat-form-field fxFlex>
          <input matInput [(ngModel)]="user.username" name="username" id="username" [placeholder]="'desc.account_name' | translate" required cdkTrapFocus [cdkTrapFocusAutoCapture]="true" />
        </mat-form-field>

        <mat-form-field fxFlex>
          <input matInput [(ngModel)]="user.nazev" name="nazev" id="nazev" [placeholder]="'desc.username' | translate" required />
        </mat-form-field>

        <mat-form-field fxFlex>
          <input matInput [(ngModel)]="user.email" name="email" id="email" [placeholder]="'desc.email' | translate"  />
        </mat-form-field>

        <!--          //TODO add password to api calls when loading/saving users data AND SECURE THAT API BECAUSE NOW EVERYONE CAN GET USERS DATA (for now there arent any passwords in response, see http://localhost:4200/api/users/all) -->
        <!--        <mat-form-field fxFlex>-->
        <!--          <input matInput [(ngModel)]="user.heslo" name="heslo" id="heslo" [placeholder]="'desc.password' | translate" required />-->
        <!--        </mat-form-field>-->

        <mat-form-field fxFlex>
          <input matInput [(ngModel)]="user.poznamka" name="poznamka" id="poznamka" [placeholder]="'desc.note' | translate"  />
        </mat-form-field>

        <mat-form-field fxFlex>
          <mat-label>{{ 'desc.user_roles' | translate }}</mat-label>
          <mat-select [(ngModel)]="user.role" id="role" name="role">

            <mat-option *ngFor="let role of roles" [value]="role.id">{{'desc.' + role.name | translate }}</mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field fxFlex>
          <mat-label>{{ 'desc.user_owners' | translate }}</mat-label>
          <mat-select [(ngModel)]="user.owner" (ngModelChange)="user.owner=$event" id="owner" name="owner">

            <mat-option [value]="null"></mat-option>
            <mat-option *ngFor="let owner of owners" [value]="owner.id.toString()">{{ owner.name }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <button mat-flat-button color="primary" (click)="newUser()" type="button">{{ 'button.add_user' | translate }}</button>
      <button mat-flat-button color="primary" type="submit" [disabled]="!userForm.form.valid">{{ 'button.save' | translate }}</button>
    </form>
  </div>
</div>
