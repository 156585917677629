<div class="app-facet-wrapper">
  <app-facet-used></app-facet-used>

  <mat-accordion multi="true" *ngIf="state.searchResults">
    <mat-expansion-panel *ngIf="isInResults() && !state.filterByDate" [expanded]="true" class="app-facet-date">
      <mat-expansion-panel-header expandedHeight="50px" collapsedHeight="50px">
        <mat-panel-title>
          {{ 'facet.date' | translate}}
        </mat-panel-title>
      </mat-expansion-panel-header>

      <div fxLayout="row" fxLayoutGap="16px">
        <div fxFlex="40%">
          <mat-form-field class="app-full-width">
            <input matInput
              [placeholder]="'modal.from' | translate"
              [(ngModel)]="state.start_year"
              id="start_date" name="start_year"
              size="4"
              type="number"
              (keyup.enter)="state.addDateFilter()"
              [disabled]="this.state.loadingData">
          </mat-form-field>
        </div>
        <div fxFlex="40%">
          <mat-form-field class="app-full-width">
            <input matInput
              [placeholder]="'modal.till' | translate"
              [(ngModel)]="state.end_year"
              id="end_date" name="end_year"
              type="number"
              size="4"
              (keyup.enter)="state.addDateFilter()"
              [disabled]="this.state.loadingData">
          </mat-form-field>
        </div>
        <div fxFlex="20%" class="app-fx-end">
          <div fxFlex>
            <a *ngIf="!state.filterByDate"
               (click)="state.addDateFilter()"
               [matTooltip]="'facet.tooltip.use_this_filter' | translate"
               [class]="state.loadingData ? 'loadingData' : ''">
              <mat-icon>{{ config.icons.send }}</mat-icon>
            </a>
          </div>
        </div>
      </div>
    </mat-expansion-panel>

    <mat-expansion-panel *ngIf="isInResults() && !state.filterByVolume" [expanded]="true" class="app-facet-date">
      <mat-expansion-panel-header expandedHeight="50px" collapsedHeight="50px">
        <mat-panel-title>
          {{ 'facet.volume' | translate}}
        </mat-panel-title>
      </mat-expansion-panel-header>

      <div fxLayout="row" fxLayoutGap="16px">
        <div fxFlex="100%">
          <mat-form-field class="app-full-width">
            <input matInput
                   [placeholder]="'facet.volume_number' | translate"
                   [(ngModel)]="state.volume_id_for_search"
                   id="volume_id_for_search" name="volume_id_for_search"
                   type="string"
                   (keyup.enter)="state.addVolumeFilter()"
                   [disabled]="this.state.loadingData">
          </mat-form-field>
        </div>
        <div class="app-fx-end">
          <div fxFlex>
            <a *ngIf="!state.filterByVolume"
               (click)="state.addVolumeFilter()"
               [matTooltip]="'facet.tooltip.use_this_filter' | translate"
               [class]="state.loadingData ? 'loadingData' : ''">
              <mat-icon>{{ config.icons.send }}</mat-icon>
            </a>
          </div>
        </div>
      </div>
    </mat-expansion-panel>

    <ng-container *ngFor="let facet of state.searchResults['responseHeader']['params']['facet.field']">
      <mat-expansion-panel *ngIf="state.searchResults['facet_counts']['facet_fields'][facet].length > 1" [expanded]="true">
        <mat-expansion-panel-header expandedHeight="50px" collapsedHeight="50px">
          <mat-panel-title>
            {{ 'facet.'+facet | translate }}
          </mat-panel-title>
        </mat-expansion-panel-header>

        <mat-list role="list">
          <mat-list-item class="facet-item-list"
                         *ngFor="let item of state.searchResults['facet_counts']['facet_fields'][facet]"
                         (click)="addFilter(facet, item.name)"
                         [class]="state.loadingData ? 'loadingData' : ''">
            <!-- <mat-icon *ngIf="isUsed(facet, item.name) > -1">check_circle</mat-icon> -->
            <ng-container [ngSwitch]="facet">
              <a *ngSwitchCase="'stav'" [class]="state.loadingData ? 'loadingData' : ''">{{'record.StavIssue.' + item.name | translate}}</a>
              <a *ngSwitchCase="'vydani'" [class]="state.loadingData ? 'loadingData' : ''">{{'record.publication.' + item.name | translate}}
                <ng-container *ngIf="item.name === ''">{{ 'desc.without_name' | translate }}</ng-container>
              </a>
              <a *ngSwitchCase="'mutace'" [class]="state.loadingData ? 'loadingData' : ''">{{'record.mutations.' + item.name | translate}}
                <ng-container *ngIf="item.name === ''">{{ 'desc.without_name' | translate }}</ng-container>
              </a>
            <a *ngSwitchCase="'vlastnik'" [class]="state.loadingData ? 'loadingData' : ''">{{state.owners[item.name].name}}
                <ng-container *ngIf="item.name === ''">{{ 'desc.without_name' | translate }}</ng-container>
            </a>
              <a *ngSwitchDefault [class]="state.loadingData ? 'loadingData' : ''">{{ item.name === "N" ? "Nečitelné" : item.name }}
                <ng-container *ngIf="item.name === ''">{{ 'desc.without_name' | translate }}</ng-container>
              </a>
            </ng-container>
            <a [class]="state.loadingData ? 'loadingData' : ''">
              ({{ item.value}})
            </a>
          </mat-list-item>
        </mat-list>
      </mat-expansion-panel>
    </ng-container>
  </mat-accordion>
</div>
