<mat-list role="list" class="app-list-used-facet" *ngIf="(state.q && state.q !== '') || state.filters.length > 0 || state.filterByDate || state.filterByVolume">
  <h3 mat-subheader>
    {{ 'facet.title.active_filters' | translate }}
    <a [matTooltip]="'facet.tooltip.delete_all_filters' | translate" matTooltipPosition="above" (click)="removeAll()">
      <mat-icon>close</mat-icon>
    </a>
  </h3>
  <mat-list-item role="listitem" *ngIf="state.q && state.q !== ''">
    <a [matTooltip]="'facet.tooltip.delete_this_filter' | translate" matTooltipPosition="above" (click)="state.removeQuery()">
      <mat-icon>close</mat-icon>
    </a>
    {{ 'facet.title.active_filters' | translate }}:&#160;
    <span class="app-font-bold">{{ state.q }}</span>
  </mat-list-item>

  <mat-list-item role="listitem" *ngFor="let filter of state.filters; let idx= index">
    <a [matTooltip]="'facet.tooltip.delete_this_filter' | translate" matTooltipPosition="above" (click)="remove(idx)">
      <mat-icon>close</mat-icon>
    </a>
    {{ 'facet.' + filter.field | translate }}:&#160;
    <span [ngSwitch]="filter.field">
      <span class="app-font-bold" *ngSwitchCase="'stav'">{{ 'record.StavIssue.' + filter.value | translate }}</span>
      <span class="app-font-bold" *ngSwitchCase="'mutace'">{{ 'record.mutations.' + filter.value | translate }}</span>
      <span class="app-font-bold" *ngSwitchCase="'vydani'">{{ 'record.publication.' + filter.value | translate }}</span>
      <span class="app-font-bold" *ngSwitchCase="'vlastnik'">{{ state.owners[filter.value].name }}</span>
      <span class="app-font-bold" *ngSwitchDefault>{{ filter.value === "" ? ("desc.without_name" | translate) : filter.value}}</span>
    </span>
  </mat-list-item>

  <mat-list-item role="listitem"  *ngIf="state.filterByDate">
    <a [matTooltip]="'facet.tooltip.delete_this_filter' | translate" matTooltipPosition="above" (click)="state.removeDateFilter()">
      <mat-icon>close</mat-icon>
    </a>
    {{ 'facet.date' | translate }}:&#160;
    <span class="app-font-bold">{{ start_year || this.state.start_year }}</span>&#160;-&#160;<span class="app-font-bold">{{ end_year || this.state.end_year }}</span>
  </mat-list-item>

  <mat-list-item role="listitem"  *ngIf="state.filterByVolume">
    <a [matTooltip]="'facet.tooltip.delete_this_filter' | translate" matTooltipPosition="above" (click)="state.removeVolumeFilter()">
      <mat-icon>close</mat-icon>
    </a>
    {{ 'facet.volume' | translate }}:&#160;
    <span class="app-font-bold">{{ volume_id_for_search }}</span>
  </mat-list-item>
</mat-list>
