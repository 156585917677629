<div class="app-home-wrapper">
  <div fxFlex>
    <div fxLayout="row" class="app-row-logo">
      <div fxFLex>
        <a [routerLink]="['']" class="app-logo" matTooltip="Systém pro vyhledávání periodik">
          <span class="cyan-text text-lighten-3">{{ 'app.title1' | translate }}</span> {{ 'app.title2' | translate }}
        </a>
        <mat-divider></mat-divider>
        <span class="app-desc">{{ 'app.desc' | translate }}</span>
      </div>
    </div>
    <div fxLayout="row" class="app-row-search-bar">
      <!-- <app-search-bar fxFlex></app-search-bar> -->
    </div>
    <div fxLayout="row" fxLayoutGap="16px" class="app-row-items">
      <app-result-item fxFlex="405px" *ngFor="let item of state.tituly" [item]="item"></app-result-item>
    </div>
  </div>
</div>
