<h1 mat-dialog-title>
	{{ 'desc.password_reset' | translate }}
</h1>
<form [formGroup]="hesloForm" (ngSubmit)="onSubmit()" >
	<mat-dialog-content class="mat-typography">
		<mat-form-field class="app-full-width app-gap-bottom">
			<input matInput [placeholder]="'desc.old_password' | translate" [formControl]="f.oldheslo" type="password" cdkFocusInitial>
			<mat-error *ngIf="f.oldheslo.hasError('required')">
				{{ 'desc.old_password_required' | translate }}
			</mat-error>
		</mat-form-field>
		<mat-form-field class="app-full-width  app-gap-bottom">
			<input matInput [placeholder]="'desc.new_password' | translate" [formControl]="f.newheslo" type="password" />
			<mat-error *ngIf="f.newheslo.hasError('required')">
				{{ 'desc.new_password_required' | translate }}
			</mat-error>
		</mat-form-field>
		<div *ngIf="error" class="alert alert-danger mt-3 mb-0 app-gap-bottom">{{ error }}</div>
	</mat-dialog-content>
	<mat-dialog-actions>
		<button mat-button [disabled]="loading" type="submit">OK</button>
		<button mat-button mat-dialog-close="true" type="button">{{ 'button.cancel' | translate }}</button>
	</mat-dialog-actions>
</form>
