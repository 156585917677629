<h1 mat-dialog-title>
  {{ 'desc.add_exemplars_from_vdk-set_record' | translate }} - {{ state.currentIssue.nazev }}
</h1>

<div mat-dialog-content>
  <div fxLayout="row" fxLayoutGap="16px">
    <mat-form-field fxFlex>
      <input matInput [placeholder]="'URL' | translate" [id]="'url'" [name]="'url'" [(ngModel)]="url" type="text">
    </mat-form-field>
  </div>

  <div fxLayout="row" fxLayoutGap="16px">
    <mat-form-field fxFlex>
      <mat-label>{{ 'record.ownership' | translate }}</mat-label>
      <mat-select [id]="'owner'" [name]="'owner'" [(ngModel)]="vlastnik">
        <mat-option *ngFor="let owner of state.owners" [value]="owner.id">{{ owner.name }}</mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field fxFlex>
      <mat-label>{{ 'desc.format_by_field_i' | translate }}</mat-label>
      <mat-select [id]="'format'" [name]="'format'" [(ngModel)]="format">
        <mat-option *ngFor="let f of state.vdkFormats" [value]="f">{{ 'formatvdk.' + f | translate }}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <div class="app-gap-bottom">
    <mat-checkbox [(ngModel)]="onspecial" id="onSpecialDays">{{ 'modal.on_special_days' | translate }}</mat-checkbox>
  </div>

  <div class="app-gap-bottom">
    <mat-progress-bar *ngIf="loading" mode="indeterminate"></mat-progress-bar>
    <table mat-table [dataSource]="dataSource" class="app-table-gap-5">
      <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef>{{ 'record.carovy_kod' | translate }}</th>
        <td mat-cell *matCellDef="let row">{{ row.permonik.carovy_kod }}</td>
      </ng-container>

      <ng-container matColumnDef="year">
        <th mat-header-cell *matHeaderCellDef>
         <!--  <mat-form-field>
            <input matInput [placeholder]="'record.year' | translate" [id]="'yf'" [name]="'yf'" [(ngModel)]="year_filter" type="text" (keyup.enter)="filter()" />
          </mat-form-field> -->
          {{ 'record.year' | translate }}
        </th>
        <td mat-cell *matCellDef="let row" >{{ row.add.year }}</td>
      </ng-container>

      <ng-container matColumnDef="volume">
        <th mat-header-cell *matHeaderCellDef>{{ 'record.volume' | translate }}</th>
        <td mat-cell *matCellDef="let row" >{{ row.add.volume }}</td>
      </ng-container>

      <ng-container matColumnDef="start">
        <th mat-header-cell *matHeaderCellDef>{{ 'desc.start' | translate }}</th>
        <td mat-cell *matCellDef="let row" >
          <input [(ngModel)]="row.add.start_cislo" type="text" />
        </td>
      </ng-container>

      <ng-container matColumnDef="od">
        <th mat-header-cell *matHeaderCellDef>{{ 'desc.from' | translate }}</th>
        <td mat-cell *matCellDef="let row" >
          <input [(ngModel)]="row.add.start_date" type="text" />
        </td>
      </ng-container>

      <ng-container matColumnDef="do">
        <th mat-header-cell *matHeaderCellDef>{{ 'desc.till' | translate }}</th>
        <td mat-cell *matCellDef="let row" >
          <input [(ngModel)]="row.add.end_date" type="text" />
        </td>
      </ng-container>

      <ng-container matColumnDef="add">
        <th mat-header-cell *matHeaderCellDef>
          <mat-checkbox (change)="toggleSelection()" [(ngModel)]="selection" [id]="'selection'" [indeterminate]="selection === null"></mat-checkbox>
        </th>
        <td mat-cell *matCellDef="let row" >
          <mat-checkbox (change)="checkSelection()" [(ngModel)]="row['selected']" [id]="'add_' + row.permonik.carovy_kod"></mat-checkbox>
        </td>
      </ng-container>

      <ng-container matColumnDef="orig">
        <td mat-cell *matCellDef="let row" colspan="6">{{ stringify(row.orig) }}</td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr mat-row *matRowDef="let row; let idx = index; columns: displayedColumns;" [class.error]="hasError(row)"></tr>
      <tr mat-row *matRowDef="let row; columns: ['orig']; when: isOrigRow" [class.app-row-orig]="isOrigRow"></tr>
    </table>
  </div>

</div>
<div mat-dialog-actions>
  <button mat-button *ngIf="prepared" (click)="ok()" [disabled]="!state.logged">{{ 'button.add' | translate}}</button>
  <button mat-button (click)="prepare()">{{ 'button.prepare' | translate }}</button>
  <button mat-button mat-dialog-close>{{ 'button.close' | translate }}</button>
</div>


<!-- <mz-modal [fixedFooter]="true">
  <mz-modal-header>
    {{'Add exemplars from vdk-set record' | translate}}. {{state.currentIssue.nazev}}
  </mz-modal-header>

  <mz-modal-content>
    <mz-input-container>
      <input mz-input [label]="'URL' | translate" [id]="'url'" [name]="'url'" [(ngModel)]="url" type="text">
    </mz-input-container>

    <div class="row">
      <div class="col s2">
        <mz-select-container>
          <select mz-select [id]="'owner'" [name]="'owner'" [(ngModel)]="vlastnik" [label]="'record.ownership' | translate">
                  <option *ngFor="let owner of state.owners" [value]="owner.name">{{owner.name}}</option>
          </select>
        </mz-select-container>
      </div>
      <div class="col s4">
        <mz-select-container>
          <select mz-select [id]="'format'" [name]="'format'" [(ngModel)]="format" [label]="'Format pod pole \'i\''">
                  <option *ngFor="let f of state.vdkFormats" [value]="f">{{'formatvdk.' + f | translate}}</option>
          </select>
        </mz-select-container>
      </div>
      <div class="col s6">
        <mz-checkbox-container>
          <input mz-checkbox
                 [(ngModel)]="onspecial"
                 [label]="'modal.on_special_days' | translate"
                 id="onSpecialDays"
                 type="checkbox" />
        </mz-checkbox-container>

      </div>
    </div>

    <div class="mat-elevation-z8 app-progressbar-wrapper">
      <ng-progress id="bar-1" #progressBar [color]="'#4285f4'"></ng-progress>
      <table mat-table [dataSource]="dataSource" >

        <ng-container matColumnDef="id">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let row"> {{row.permonik.carovy_kod}} </td>
        </ng-container>

        <ng-container matColumnDef="year">
          <th mat-header-cell *matHeaderCellDef><mz-input-container>
            <input mz-input [label]="'year' | translate" [id]="'yf'" [name]="'yf'"
                   [(ngModel)]="year_filter" type="text"
                   (keyup.enter)="filter()" />
          </mz-input-container></th>
          <td mat-cell *matCellDef="let row" >{{row.add.year}}</td>
        </ng-container>

        <ng-container matColumnDef="volume">
          <th mat-header-cell *matHeaderCellDef>{{'volume' | translate}}</th>
          <td mat-cell *matCellDef="let row" >{{row.add.volume}}</td>
        </ng-container>

        <ng-container matColumnDef="start">
          <th mat-header-cell *matHeaderCellDef>{{'start' | translate}}</th>
          <td mat-cell *matCellDef="let row" >
              <input [(ngModel)]="row.add.start_cislo" type="text" /></td>
        </ng-container>

        <ng-container matColumnDef="od">
          <th mat-header-cell *matHeaderCellDef>Od</th>
          <td mat-cell *matCellDef="let row" >
              <input [(ngModel)]="row.add.start_date" type="text" />
          </td>
        </ng-container>

        <ng-container matColumnDef="do">
          <th mat-header-cell *matHeaderCellDef>Do</th>
          <td mat-cell *matCellDef="let row" >
              <input [(ngModel)]="row.add.end_date" type="text" />
          </td>
        </ng-container>

        <ng-container matColumnDef="add">
          <th mat-header-cell *matHeaderCellDef>
            <mz-checkbox-container>
              <input mz-checkbox
                    (change)="toggleSelection()"
                    [(ngModel)]="selection"
                    [id]="'selection'"
                    [indeterminate]="selection === null"
                    type="checkbox" />
            </mz-checkbox-container>
          </th>
          <td mat-cell *matCellDef="let row" >
            <mz-checkbox-container>
              <input mz-checkbox
                    (change)="checkSelection()"
                    [(ngModel)]="row['selected']"
                    [id]="'add_' + row.permonik.carovy_kod"
                    type="checkbox" />
            </mz-checkbox-container>
          </td>
        </ng-container>

        <ng-container matColumnDef="orig">
          <td mat-cell *matCellDef="let row" colspan="6" class="app-row-orig">{{stringify(row.orig)}}</td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; let idx = index; columns: displayedColumns;" [class.error]="hasError(row)"></tr>
        <tr mat-row *matRowDef="let row; columns: ['orig']; when: isOrigRow"></tr>
      </table>
    </div>
  </mz-modal-content>

  <mz-modal-footer>
    <button *ngIf="prepared" mz-button [flat]="true" (click)="ok()" [disabled]="!state.logged">Add</button>
    <button mz-button [flat]="true" (click)="prepare()">Prepare</button>
    <button mz-button [flat]="true" mz-modal-close>{{'button.close' | translate}}</button>
  </mz-modal-footer>
</mz-modal> -->
