<mat-toolbar class="app-toolbar-calendar" *ngIf="showCalendar()">
  <div fxFlex="320px">
    <app-toolbar-count></app-toolbar-count>
  </div>
  <div fxFlex class="app-fx-pagination">
    <app-toolbar-pagination-calendar></app-toolbar-pagination-calendar>
  </div>
  <div fxFlex class="app-fx-title">
    <span *ngIf="state.currentTitul" class="app-font-bold">{{ state.currentTitul.meta_nazev }}</span>
  </div>
  <div fxFlex class="app-fx-views">
    <app-toolbar-nav-views></app-toolbar-nav-views>
  </div>
</mat-toolbar>


<mat-toolbar class="app-toolbar-result" *ngIf="showResult()">
  <div fxFlex="320px" class="app-fx-left">
    <app-toolbar-count></app-toolbar-count>
  </div>
  <div fxFlex class="app-fx-right">
    <span *ngIf="state.currentTitul" class="app-font-bold">{{ state.currentTitul.meta_nazev }}</span>
    <!-- <app-toolbar-pagination-result></app-toolbar-pagination-result> -->
    <!-- <span class="app-font-bold" [innerHtml]="header" ></span> -->
  </div>
</mat-toolbar>


<mat-toolbar class="app-toolbar-issue" *ngIf="showIssue()">
  <div fxFlex class="app-fx-left">
    <span>{{ 'desc.record' | translate }}: <strong>{{ state.currentTitul.meta_nazev }} {{ state.currentIssue.datum_vydani | date: "dd/MM/yyyy" }}</strong></span>
    <span class="app-pipe"></span>
    <a [routerLink]="'/result/'+ state.currentTitul.id" [matTooltip]="'desc.display_table' | translate">
      <mat-icon>{{ config.icons.view_table }}</mat-icon>
    </a>
  </div>
  <div fxFlex class="app-fx-right">
    <button mat-flat-button (click)="saveRecord()" [disabled]="!state.logged">{{ 'button.save' | translate }}</button>
    <button mat-flat-button (click)="openCloneDialog()" [disabled]="!state.logged">{{ 'button.clone' | translate }}</button>
    <button mat-flat-button (click)="deleteRecord()" [disabled]="!state.logged">{{ 'button.delete' | translate }}</button>
    <button mat-flat-button (click)="addVDKEx()" [disabled]="!state.logged">{{ 'button.add_from_vdk' | translate }}</button>
  </div>
</mat-toolbar>
